/* Auth css */
.signup_form {
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
}

.form_inputs {
  width: 394px;
  height: 54px;
  border: 1px solid rgba(38, 50, 56, 0.51);
  border-radius: 27px;
  padding-left: 15px;
}

.PhoneInputInput {
  border: none;
  border-radius: 27px;
}

.btn_theme {
  width: 394px;
  height: 54px;
  color: #fff;
  background: #9b62cd;
  border-radius: 30px;
  border: none;
  font-weight: bold;
}

.btn_theme2 {
  background: linear-gradient(180deg, #9b62cd 0%, rgba(98, 70, 234, 0) 253.64%);
}

.link_theme {
  color: rgba(98, 70, 234, 0.7);
  text-decoration: none;
}

.paymit-logo {
  position: absolute;
  left: 5.42%;
  right: 87.64%;
  top: 6.15%;
  bottom: 89.84%;
  color: #2b2c34;
}

.body-bg {
  /* background: linear-gradient(
    173.44deg,
    rgba(209, 209, 233, 0.3) 5.15%,
    rgba(209, 209, 233, 0.3) 119.54%
  ); */
  background: #f6f4ff;
  /* transform: rotate(176.43deg); */
}

.no_underline {
  text-decoration: none;
}

/* Products css */
/* country */
.y-scroll {
  overflow-y: scroll;
}

.active {
  color: #9b62cd !important;
}

.vh-15 {
  height: 15vh;
}

.vh-85 {
  height: 85vh;
}

.vh-10 {
  height: 10vh;
}

.vh-90 {
  height: 90vh;
}

.header_profile_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #9b62cd;
}

.text-red {
  color: #ff0000;
}

.border-top-left-radius {
  border-top-left-radius: 60px;
  padding-left: 5vw;
  padding-right: 5vw;
}

.progress-bar {
  background: #9b62cd;
  border-radius: var(--bs-progress-border-radius);
}

.progress {
  background: #6246ea1a;
  height: 10px;
}

.body-bg2 {
  background: #1e1e1e;
}

.search_group {
  height: 40px;
  width: 397px;
}

.border_left_search {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: none;
}

.border_right_search {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: none;
}

.border_left_country {
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  border-right: none;
}

.border_right_country {
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
  border-left: none;
}

/* providers */
.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

.providers_list {
  height: 200px;
  border: 0.796px solid rgba(0, 0, 0, 0.13);
  /* box-shadow: 0px 0.796px 3.184px rgba(0, 0, 0, 0.15); */
  border-radius: 40px;
  cursor: pointer;
}

.selected {
  border: 4px solid #9b62cd;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.cursor-pointer {
  cursor: pointer;
}

.check_radio {
  border: 1px solid rgba(0, 0, 0, 0.13);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 0.5rem;
}

.selected_radio {
  border: 2px solid #9b62cd;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  color: #9b62cd;
}

/* product details */
.text-small {
  font-size: 85%;
}

.to_upload {
  border: 1px dashed rgba(38, 50, 56, 0.4);
  border-radius: 30px;
  height: 100px;
}

/* payment details */
.rate_box {
  background: rgba(98, 70, 234, 0.1);
  border: 1px solid rgba(38, 50, 56, 0.39);
  border-radius: 30px;
}

.text-theme {
  color: #9b62cd;
}

/* transaction history */
.transaction_indicator_holder {
  border: 0.722892px solid rgba(151, 151, 151, 0.5);
  border-radius: 10.8434px;
  width: 60px;
  height: 60px;
}

.text-muted {
  color: rgba(38, 50, 56, 0.5) !important;
}

.small-modal {
  --bs-modal-width: 400px;
}

.modal-content {
  border: none !important;
  border-radius: 30px;
}

.details_modal_card {
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.text-success {
  color: #72c487 !important;
}

.bg-theme {
  background: #9b62cd;
}

.dotted {
  border: 2px dashed rgba(38, 50, 56, 0.21);
}

/* edit profile */
.media-upload {
  width: 99px;
  height: 99px;
  background: #9b62cd;
  border-radius: 50%;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3vw;
}

.form-control:focus {
  border: 1px solid rgba(38, 50, 56, 0.51) !important;
  border-left: none;
  box-shadow: none;
  outline: none;
}

.PhoneInputInput:focus-visible {
  border: none;
  outline: none;
}

.btn:hover,
.btn:focus {
  background: #9b62cd;
  color: #fff;
}

.btn_theme2:hover,
.btn_theme2:focus {
  background: linear-gradient(180deg, #9b62cd 0%, rgba(98, 70, 234, 0) 253.64%);
}

/* admin */
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.sidebar_menu {
  height: calc(100vh - 30px);
  margin: 15px 0px 15px 15px;
}

.text-grey {
  color: #263238bd;
}

.transaction_row {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.13);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  /* border-radius: 20px; */
}

td {
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row; */
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.text-smaller {
  font-size: 80%;
}

.status_border_radius {
  border-radius: 10px;
}

.grey-bg {
  background: rgba(30, 30, 95, 0.1);
}

.w-fit-content {
  width: fit-content;
}

.dropdown-menu {
  margin-top: 15px;
}

.no-underline {
  text-decoration: none;
}

.fw-bold {
  font-weight: 600 !important;
}

.pencil_icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  background: white;
  box-shadow: 0px 2px 9px rgb(0 0 0 / 25%);
}

.dropdown-absolute {
  position: relative;
  top: 30px;
  left: 80%;
  width: fit-content;
}

.rate_card {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  height: 56px !important;
}

.h-0 {
  height: 0px;
}

.details-modal {
  --bs-modal-width: 530px;
}

.receipt-modal {
  --bs-modal-width: 478px;
}

.landing-text {
  font-weight: 900;
  font-size: 72px;
  line-height: 70px;
  text-align: center;
  color: #2b2c34;
}

.w-70 {
  width: 70%;
}

.vh-65 {
  height: 65vh;
}

.absolute-rocket {
  position: absolute;
  left: 0%;
  top: 43%;
}

.mt-10 {
  margin-top: 5rem;
}

.side-text {
  font-weight: 700;
  font-size: 80px;
  line-height: 77px;
  color: #263238;
}

.signature-absolute {
  position: absolute;
  left: 40%;
  top: -30px;
}

.x-scroll {
  overflow-x: scroll;
}

.testimonial_img {
  width: 300px;
  height: 300px;
}

.faq-text {
  font-weight: 700;
  font-size: 80px;
  line-height: 78px;
  text-align: center;
  color: #263238;
  margin-bottom: 4rem;
  margin-top: 3rem;
}

.faint-text {
  opacity: 0.5;
}

.blob-1 {
  position: absolute;
  right: 0%;
  top: 0%;
  /* z-index: -1; */
}

.blob-2 {
  position: absolute;
  left: 0%;
  top: 30%;
}

.drop-down-menu {
  box-shadow: 0px 1px 11px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.right-dropdown {
  translate: 155px !important;
  margin-top: -20px;
}

.nav i {
  width: 20px;
  height: 20px;
}

.currency_card {
  background: rgba(0, 0, 0, 0.41);
  border: 2.725px solid #ffffff;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
  border-radius: 16.35px;
}

.currency_card img {
  filter: brightness(60%);
}

.border-radius-0 {
  border-radius: 0px !important;
}

.weird-nav {
  border-bottom: 1px solid rgba(104, 113, 130, 0.58);
  margin-bottom: 3rem;
  padding-bottom: 1px;
  height: 35px;
}

.weird-nav-item {
  font-size: 20px;
  line-height: 24px;
  color: #263238;
  opacity: 0.6;
  margin-right: 1.5rem;
  padding-bottom: 8px;
}

.selected_nav {
  opacity: 1;
  border-bottom: 3px solid #9b62cd;
}

.special-height {
  height: calc(90vh - 6rem);
}

.special-height2 {
  height: calc(90vh - 9rem - 35px);
}

.newsletter {
  background-image: url("./assets/img/jigsaw.png");
  opacity: 0.4;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  background-size: cover;
  height: 100%;
}

.icon-container {
  width: 85px;
  height: 71px;
  background-color: rgb(98, 70, 234, 0.1);
  border-radius: 5px;
}

.bg-theme2 {
  background-color: rgb(98, 70, 234, 0.1);
}

.no-underline {
  text-decoration: none;
}

/* screens less that 767px */ 
@media screen and (max-width: 767px) {
  .landing-text, .faq-text {
    font-size: 36px;
    line-height: 42px;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .side-text {
    font-size: 45px;
    line-height: 42px;
  }
  .w-md-100 {
    width: 100%;
  }
  .f-md-28 {
    font-size: 28px !important;
  }
}